import React from 'react';

import Button from '../Button';

import { Container } from './styles';

import { ModalProps } from './interface';

const Modal: React.FC<ModalProps> = ({
  className,
  title,
  buttonText,
  state,
  text,
  footerContend,
  onClick,
}) => {
  return (
    <Container state={!state} className={className}>
      <div className="container justifyment-row">
        <div className="modal__content">
          {title && <h1 className="modal__title">{title}</h1>}
          {/* prepare for reciving text as array or string */}
          {Array.isArray(text) ? text.map((item, index) => <p key={index} className="modal__p">{item}</p>) : <p className="modal__p">{text}</p>}
        </div>
        <div className='modal__footer'>
          <div className="modal__footer__content">
            {footerContend.map((item, index) => <p key={index}>{item}</p>)}
          </div>
          <Button className="modal__button is-hover" hiddenIcon={true} onClick={onClick}>
            {buttonText}
          </Button>
        </div>
      </div>
      <div className='backstage'></div>
    </Container>
  );
};

export default Modal;
