import IcServicing from "../../../assets/images/ic_Servicing.svg";
import IcFormalized from "../../../assets/images/ic_Formalized-operations.svg";
import IcGestionsWallet from "../../../assets/images/ic_Gestion-wallet-credit.svg";
import IcEngineCredit from "../../../assets/images/ic_Engine_credit.svg";
import IcPayment from "../../../assets/images/ic_Payment.svg";
import IcEducation from "../../../assets/images/ic_Education.svg";
import IcSecurity from "../../../assets/images/ic_Security-premist.svg";
import IcFGTS from "../../../assets/images/ic_FGTS.svg";

export default {
  cardsBankAsService: [
    {
      image: IcEngineCredit,
      altImage: 'Motor de crédito',
      title: "Motor de Crédito",
      text:
        "Análise de forma rápida e automática dos seus parâmetros de crédito.",
    },
    {
      image: IcFormalized,
      altImage: "Formalização de Operações de Crédito",
      title: "Formalização de Operações de Crédito",
      text:
        "Com nossas APIs, facilitamos e integramos todo o processo.",
    },
    {
      image: IcServicing,
      altImage: "Servicing",
      title: "Servicing",
      text:
        "Serviços complementares, como criação de políticas de crédito e cobrança, análise e formalização de garantias, verificação e validação de poderes, entre outros.",
    },
    {
      image: IcGestionsWallet,
      altImage: 'Gestão de Carteira de Crédito',
      title: "Gestão da Carteira de Crédito",
      text:
        "Controle total da sua carteira, realizando as conciliações e interfaces necessárias.",
    },
  ],
  cardsCreditConsigned: [
    {
      image: IcPayment,
      altImage: "Quitação de dívida",
      title: "Quitação de Dívida",
      text:
        "Concessão de crédito para quitação  de dívidas “caras”, com alongamento de prazos, redução de taxas e parcelas para caber no bolso.",
    },
    {
      image: IcEducation,
      altImage: "Educação financeira",
      title: "Educação Financeira",
      text:
        'Também oferecemos palestras e ferramentas para ajudar os colaboradores a aprenderem sobre organização financeira.',
    },
    {
      image: IcSecurity,
      altImage: 'Seguro prestamista',
      title: "Seguro Prestamista",
      text: "Proteção em casos de eventualidade como o desligamento.",
    },
    {
      image: IcFGTS,
      altImage: 'FGTS',
      title: "FGTS",
      text:
        "Em breve taxas ainda menores com a opção de usar o FGTS como garantia.",
    },
  ],
  cardsSliderItems: [
    {
      content: [
        {
          id: 0,
          title: "Política de Crédito e Cobrança",
        },
        {
          id: 1,
          title: "Motor de Crédito",
        },
        {
          id: 2,
          title: "Servicing",
        },
        {
          id: 3,
          title: "Atendimento Multiplataforma",
        },
        {
          id: 4,
          title: "Formalização Bancária e Cessão de Crédito",
        },
        {
          id: 5,
          title: "Gestão da Carteira de Crédito",
        },
        {
          id: 6,
          title: "Opção White Label",
        },
      ],
    },
  ],
};
