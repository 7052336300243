import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.div`
  position: sticky;
  width: 100%;
  padding: 20px 0;

  bottom: ${({ state }) => (state ? '0px!important' : 'calc(-100vh + 80px);')};
  display: ${({ state }) => (state ? 'block' : 'none')};
  background-color: ${({ theme }) => theme.bg.seventh};
  transition: 0.8s;

  .justifyment-row {
    align-items: center;
  }

  .container {
    display: flex;
    height: 100%;
  }

  .msg__image-container {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.font.white};
    margin-right: 0;
    margin-bottom: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    ${mediaQuery.desktop`
        margin-right: 25px;
        margin-bottom: 0px;
    `}
  }

  .msg__content {
    display: flex;
    max-width: 100%;
    width: 100%;
    
    flex-direction: column;
    color: ${({ theme }) => theme.font.white};

    ${mediaQuery.desktop`
        max-width: 85%;
    `}

    .msg__title {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
    }

    .msg__p {
      text-align: ${({ icon }) => (icon ? 'center' : 'none')};
      font-size: 14px;
      font-weight: 400;
      opacity: 0.8;
    }
  }

  .msg__button {
    height: 39px;
    font-size: 16px;
    font-weight: 600;
    background-color: ${({ theme }) => theme.bg.seventh};
    border: 1px solid #fff !important;
    margin-left: 0;
    margin-top: 25px;
    transition: 0.3s;

    ${mediaQuery.desktop`
        margin-top: 0;
        margin-left: 25px;
    `}

    &.is-hover:hover {
      background-color: ${({ theme }) => theme.bg.primary};
      border: 1px solid ${({ theme }) => theme.bg.dark};
      color: ${({ theme }) => theme.font.black};
    }
  }
`;
