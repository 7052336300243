import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.section`
  height: 100%;
  overflow: hidden;

  button {
    text-transform: uppercase;
    min-height: 53px;
  }

  .btn-home-bank,
  .btn-home-credit {
    font-weight: 600;
    /* opacity: ${({ position }) => (position > 1200 ? '0' : '1')}; */
    transition: 0.3s;

    ${mediaQuery.desktop`
      margin-top: 30px;
    `}
  }

  .container-consigned-private {
    background-color: #F9F8F7;
    margin-top: 70px;
    padding-top: 5px;

    section {
      background-color: transparent;
      
      &:first-child {
        /* margin-top: 80px; */
      }
    }
  }

  #bank-as-service {
    button {
      width: 100%;

      ${mediaQuery.desktop`
        width: inherit;
      `}
    }
  }

  .section-consigned-service {
    background-color: ${({ theme }) => theme.bg.second};
    padding-bottom: 80px;
    margin-top: 80px;

    overflow: hidden;

    ${mediaQuery.desktop`
      margin-top: 150px;
      padding-top: 50px;
      padding-bottom: 150px;
    `}

    .title {
      background-color: ${({ theme }) => theme.bg.second};
      margin: none;
      padding: none;

      /* ${mediaQuery.desktop`
        margin-top: 60px;
      `} */

      h2 {
        color: ${({ theme }) => theme.font.white};
      }

      p {
        color: ${({ theme }) => theme.font.white};
      }
    }

    .btn-consigned-service {
      width: 100%;
      background-color: ${({ theme }) => theme.bg.primary};
      color: ${({ theme }) => theme.font.third};
      font-weight: 600;
      font-size: 16px;
      margin-top: 56.45px;
      border-color: ${({ theme }) => theme.border.borderBtnWhite};

      ${mediaQuery.desktop`
        width: auto;
        margin-top: 100px;
      `}

      svg {
          path {
            fill: ${({ theme }) => theme.bg.second};
          }
      }

      &:hover {
        background-color: ${({ theme }) => theme.bg.second};
        color: ${({ theme }) => theme.font.white};

        svg {
          path {
            fill: ${({ theme }) => theme.bg.primary};
          }
        }
      }
    }
  }

  .section-consigned-private {
    margin-bottom: 0;

    .btn-home-credit {
      width: 100%;
      margin-bottom: 80px;

      ${mediaQuery.desktop`
        width: auto;
      `}
    }
  }

  .home__consigned-arrow {
    .a {
      fill: red;
    }
  }

  .highlight-home {
    margin: 0;

    ${mediaQuery.desktop`
      margin: 150px 0;
      border: 1px solid transparent;
    `}

    ${mediaQuery.desktopWide`
      background-position: 21%;
    `}

    .jumbotron {
      width: 100%;
      margin-top: 166px;
      padding: 38px;
      margin-left: auto;

      display: flex;
      align-items: center;
      justify-content: center;
      align-items: flex-start;
      text-align: center;

      &:before {
        display: none;

        ${mediaQuery.desktop`
          display: inherit;
        `}
      }

      ${mediaQuery.desktop`
        padding: 80px;
        width: 520px;
        text-align: left;
      `}

      h2 {
        font-size: 26px;

        ${mediaQuery.desktop`
          font-size: 40px;
        `}
      }

      p {
        font-size: 16px;
        font-weight: 400;
      }

      > button {
        margin-top: 30px;
        width: 100%;
        font-weight: 600;

        ${mediaQuery.desktop`
          width: auto;
        `}
      }
    }
  }
`;
