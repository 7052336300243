import styled from 'styled-components';
import { mediaQuery } from '../../assets/styles/mixins';

export const Container = styled.div`
  position: fixed;
  z-index: 999999999999;

  .justifyment-row {
    align-items: center;
  }

  .backstage {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: ${({ state }) => (state ? 'block' : 'none')};
  }

  .container {
    display: flex;
    position: fixed;
    width: 90%;
    padding: 20px;

    top: ${({ state }) => (!state ? '0px!important' : '2.5%')};
    left: 5%;
    display: ${({ state }) => (state ? 'block' : 'none')};
    background-color: ${({ theme }) => theme.bg.primary};
    transition: 0.8s;
    z-index: 1000;

    ${mediaQuery.desktop`
      width: 776px;
      padding: 40px;
      top: calc(50% - 200px);
      left: calc(50% - 388px);
    `}
  }

  .modal__image-container {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.font.white};
    margin-right: 0;
    margin-bottom: 20px;

    display: flex;
    justify-content: center;
    align-items: center;

    ${mediaQuery.desktop`
        margin-right: 25px;
        margin-bottom: 0px;
    `}
  }

  .modal__content {
    display: flex;
    max-width: 100%;
    width: 100%;
    
    flex-direction: column;
    color: ${({ theme }) => theme.font.seventh};

    .modal__title {
      text-transform: uppercase;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;

      ${mediaQuery.desktop`
        font-size: 24px;
      `}
    }

    .modal__p {
      text-align: ${({ icon }) => (icon ? 'center' : 'none')};
      color: ${({ theme }) => theme.font.eigth};
      margin: 15px 0 0;
      font-size: 14px;
      font-weight: 400;
      opacity: 0.8;

      ${mediaQuery.desktop`
        font-size: 16px;
        margin: 24px 0 0;
      `}
    }
  }

  .modal__footer {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: left;
    width: 100%;
    margin-top: 20px;

    ${mediaQuery.desktop`
      margin: 35px 0 0;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    `}

    .modal__footer__content {
      p {
        font-size: 16px;
        color: ${({ theme }) => theme.font.second};

        a {
          color: ${({ theme }) => theme.font.third};
          text-decoration: underline;
        }
      }
    }
  }

  .modal__button {
    font-size: 16px;
    font-weight: 600;
    background-color: ${({ theme }) => theme.bg.second};
    border: 2px solid #fff;
    margin-left: 0;
    margin-top: 20px;
    transition: 0.3s;
    width: 100%;

    ${mediaQuery.desktop`
      width: auto;
      margin-top: 0;
      margin-left: 25px;
    `}

    &.is-hover:hover {
      background-color: ${({ theme }) => theme.bg.primary};
      border: 2px solid ${({ theme }) => theme.bg.second};
      color: ${({ theme }) => theme.font.second};
    }
  }
`;
