import React from "react";
import { navigate } from "gatsby";

import Layout from "../components/Layout";
import Banner from "../components/Banner";
import TitleSection from "../components/TitleSection";
import Card from "../components/Card";
import Button from "../components/Button";
import Slider from "../components/Slider";
import Highlights from "../components/Highlights";
import Jumbotron from "../components/Jumbotron";
import Message from "../components/Message";
import Modal from "../components/Modal";

import { useWindowSize } from "../hooks/resizeWindow";

import { Container } from "../components/HomeStyle/styles";

import bgHome from "../assets/images/bg_banner-home.jpg";
import bgHomeMobile from "../assets/images/bg_home-mobile.jpg";
import bgHighlightHome from "../assets/images/bg-highlight-home.png";
import bgHighlightHomeMobile from "../assets/images/bg-highlight-home-mobile.png";

import contentHome from "../content/pages/Home";

const Home: React.FC = () => {
  const size = useWindowSize();

  const [positionTransition, setPositionTransition] = React.useState(0);
  const [positionButton, setPositionButton] = React.useState(0);
  const [showInitialMessage, setShowInitialMessage] = React.useState(false);

  const handleSection = React.useCallback((page: string) => {
    navigate(page);
  }, []);

  React.useEffect(() => {
    document.querySelector("body").scrollTo(0, 0);
  }, []);

  const handlePage = React.useCallback(() => {
    navigate("/quem-somos");
  }, []);

  return (
    <>
      <Layout>
        <Container position={positionTransition}>
          <Banner
            title="A gente acredita no seu negócio"
            text="Somos uma fintech que nasceu para transformar o crédito e mudar a vida de pessoas e empresas, facilitando o acesso a produtos e serviços com as melhores condições e alta tecnologia."
            backgroundImg={size.width > 800 ? bgHome : bgHomeMobile}

            textButton="conheça a zipdin"
            classJumbotron="info-banner col-md-7"
            onClick={handlePage}
          />
          <section id="bank-as-service">
            <TitleSection
              title="Bank as a Service"
              text="Oferecemos nossa expertise e tecnologia em soluções modulares ou completa, com as melhores condições, cobrindo todas as etapas para você montar a sua operação de crédito como quiser e com o mínimo esforço."
            />
            <section className="container">
              <div className="row justifyment-row">
                {contentHome.cardsBankAsService.map((card) => (
                  <Card
                    key={card.title}
                    image={card.image}
                    altImage={card.altImage}
                    title={card.title}
                    text={card.text}
                  />
                ))}
              </div>
              <div className="justifyment-row">
                <Button
                  onClick={() => handleSection('bank-as-a-service')}
                  className="btn-home-credit"
                >
                  saiba mais
                </Button>
              </div>
            </section>
          </section>
          <section id="consigned-service" className="section-consigned-service">
            <TitleSection
              className="title"
              title="Consignado as a Service"
              text="Cuidamos da sua operação de Crédito Consignado Privado para você explorar novos negócios com tranquilidade e foco. Oferecemos a solução de acordo com o que você precisa, de forma completa ou em módulos. Escolha como quer operar e rentabilize seu negócio da melhor forma: a sua."
            />
            <Slider items={contentHome.cardsSliderItems} arrowColor="#fff" />
            <div className="container justifyment-row">
              <Button
                className="btn-consigned-service"
                onClick={() => handleSection('consignado-as-a-service')}
              >
                saiba mais
              </Button>
            </div>
          </section>
          <Highlights
            className=" highlight-home"
            orientation="left"
            imageBg={size.width > 800 ? bgHighlightHome : bgHighlightHomeMobile}
          >
            <div id="pj-as-service" className="container">
              <Jumbotron
                className=" jumbotron"
                title="Crédito PJ as a Service"
                text="Solução de crédito totalmente digital para pessoas jurídicas."
              >
                <Button onClick={() => handleSection('credito-pj-as-a-service')}>
                  saiba mais
                </Button>
              </Jumbotron>
            </div>
          </Highlights>
          <section
            id="consigned-private"
            className="container-consigned-private"
          >
            <TitleSection
              title="Crédito Consignado Privado"
              text="Praticidade e facilidade para colaboradores do setor privado. Acesso ao crédito com as menores taxas e desconto direto no contracheque."
            />
            <section className="container section-consigned-private">
              <div className="row justifyment-row">
                {contentHome.cardsCreditConsigned.map((card) => (
                  <Card
                    key={card.title}
                    image={card.image}
                    altImage={card.altImage}
                    title={card.title}
                    text={card.text}
                  />
                ))}
              </div>
              <div className="justifyment-row">
                <Button
                  onClick={() => handleSection('credito-consignado-privado')}
                  className="btn-home-credit"
                >
                  saiba mais
                </Button>
              </div>
            </section>
          </section>
        </Container>
      </Layout>
      <Message
        buttonText="VER MAIS"
        title="ALERTA CONTRA GOLPE"
        text="A Zipdin não solicita pagamento antecipado para liberação de empréstimos, assim como não permite que seus parceiros façam."
        state={showInitialMessage}
        onClick={() => setShowInitialMessage(false)}
      />
      <Modal
        state={showInitialMessage}
        title="ALERTA CONTRA GOLPE"
        buttonText="OK, ENTENDI"
        text={[
          "A Zipdin não solicita pagamento antecipado para liberação de empréstimos, assim como não permite que seus parceiros façam. Infelizmente têm se tornado frequente o número de golpes no mercado com a intenção de usar indevidamente o nome de instituições financeiras para fins criminosos.",
          "Em caso de dúvida, certifique-se de que a oferta é feita por um correspondente autorizado pela Zipdin ou entre em contato através de um de nossos canais oficiais de atendimento. Entre em contato conosco:"
        ]}
        footerContend={[
          "(21) 2042-1973",
          <a href="mailto:atendimento@zipdin.com.br">atendimento@zipdin.com.br</a>
        ]}
        onClick={() => setShowInitialMessage(true)  }
      />
    </>
  );
};

export default Home;
