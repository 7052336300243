import React from 'react';

import Button from '../Button';

import { Container } from './styles';

import { MessageProps } from './interface';

const Message: React.FC<MessageProps> = ({
  className,
  title,
  buttonText,
  state,
  icon,
  text,
  onClick,
}) => {
  return (
    <Container state={state} icon={icon} className={className}>
      <div className="container justifyment-row">
        {icon && (
          <div className="msg__image-container">
            <img src={icon} alt="icone da imagem" />
          </div>
        )}
        <div className="msg__content">
          {title && <p className="msg__title">{title}</p>}
          <p className="msg__p">
            {text}
          </p>
        </div>
        <Button className="msg__button is-hover" hiddenIcon={true} onClick={onClick}>
          {buttonText}
        </Button>
      </div>
    </Container>
  );
};

export default Message;
